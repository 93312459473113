import { defaultValues, directions, months } from "./Calculator";

const calcConstants = {
	overproductionBuyBack: 2, // 2 cents per kWh buy back rate
	tiltAngles: [0, 5, 10, 15, 20, 25, 30, 35, 40],
	panelsDirections: {
		north: { dir: 0, powerAtTilt: [1211, 1152, 1083, 1008, 926, 845, 768, 695, 625] },
		east: { dir: 90, powerAtTilt: [1211, 1209, 1199, 1183, 1161, 1136, 1106, 1075, 1039] },
		south: { dir: 180, powerAtTilt: [1211, 1269, 1315, 1352, 1378, 1393, 1400, 1397, 1384] },
		west: { dir: 270, powerAtTilt: [1211, 1214, 1209, 1196, 1178, 1153, 1125, 1092, 1057] },
		se: { dir: 135, powerAtTilt: [1211, 1250, 1279, 1298, 1309, 1310, 1303, 1288, 1267] },
		sw: { dir: 225, powerAtTilt: [1211, 1254, 1287, 1311, 1325, 1332, 1329, 1320, 1303] },
	},
	monthlySun: [
		0.066,
		0.073,
		0.083,
		0.089,
		0.097,
		0.099,
		0.099,
		0.097,
		0.091,
		0.080,
		0.068,
		0.058,
	]
}, interpolateSimple = (i, a) => (
	a[i[0]] + 0.000001 * (a[i[0] + 1] - a[i[0]]) * i[1]
), interpolate = ({ x0, x1, y0, y1, x }) => {
	x0 = parseFloat(x0); x1 = parseFloat(x1); y0 = parseFloat(y0); y1 = parseFloat(y1); x = parseFloat(x);
	if (y0 === y1) return y0;
	if (x0 === x1) return (y0 + y1) * 0.5;
	return y0 + (y1 - y0) * x / (x1 - x0);
}, interpolateIndex = (x, a) => { // Requires a 1:1 function, assumed linear ascending
	// console.log({ x, a })
	if (!a.length) return [-1, 0]
	if (a[0] > x) return [0, 0] // return index 0 if the first value is higher than the target value
	var i = a.indexOf(x)
	if (i > -1) return [i, 0] // return integer index found
	while (a.length > i + 1 && a[i + 1] < x) i++
	if (a.length === i + 1) return i // return the integer index if no next value exists
	return [i, Math.round(1000000 * (x - a[i]) / (a[i + 1] - a[i]))]
// }, interpolate2D = ({ x0, x1, y0, y1, x }) => {
}, calcs = (inputs = {}) => {
	if (!inputs.hasOwnProperty('panels')) inputs = defaultValues.fields;
	// console.log(inputs, defaultValues.fields);
	var outputs = { source: inputs };
	outputs.perDirection = {};
	outputs.panelAngleIndex = interpolateIndex(inputs.panels.angle, calcConstants.tiltAngles);
	outputs.annual = {
		basePower: 0,
		potential: 0,
		estimated: 0,
		surplus: 0
	}
	directions.forEach(Dir => {
		var dir = Dir.toLowerCase();
		outputs.perDirection[dir] = {
			basePower: inputs.panels.power * (inputs.panels.qty.hasOwnProperty(dir) ? inputs.panels.qty[dir] : 0),
			annualPotential: interpolateSimple(outputs.panelAngleIndex, calcConstants.panelsDirections[dir].powerAtTilt)
		};
		// outputs.perDirection[dir].annualEstimated = outputs.perDirection[dir].annualPotential * (100 - inputs.panels.shade) * .01
		outputs.annual.basePower += outputs.perDirection[dir].basePower
		outputs.annual.potential += outputs.perDirection[dir].annualPotential
		outputs.annual.estimated += (outputs.perDirection[dir].annualEstimated = Math.round(
			outputs.perDirection[dir].basePower *
			outputs.perDirection[dir].annualPotential * (100 - inputs.panels.shade) * .00001
		))

		// outputs.perPanel[dir].
	});
	outputs.monthlySurplus = []
	outputs.monthlyGenerated = []
	outputs.monthlyExcess = []
	outputs.monthsBillReduction = []
	months.forEach((Month, i) => {
		var month = Month.toLowerCase()
		outputs.monthlyGenerated[i] = outputs.annual.estimated * calcConstants.monthlySun[i]
		outputs.monthlySurplus[i] = Math.max(0, outputs.monthlyGenerated[i] - inputs.usage[i])
		outputs.monthlyExcess[i] = (outputs.monthlySurplus[i] > 0)
		outputs.monthsBillReduction[i] = inputs.usage[i] ? 
			Math.min(outputs.monthlyGenerated[i], inputs.usage[i]) * inputs.kwhPrice + outputs.monthlySurplus[i] * calcConstants.overproductionBuyBack
			: outputs.monthlyGenerated[i] * inputs.kwhPrice
	})
	
	outputs.annual.surplus = outputs.monthlySurplus.reduce((a, b) => (a + b))
	outputs.max = Math.max(Math.max.apply( null, inputs.usage) ,Math.max.apply( null, outputs.monthlyGenerated))
	outputs.normalize = 1 / outputs.max
	outputs.billReduction = Math.round(outputs.monthsBillReduction.reduce((a, b) => (a + b)) * .000833333333) // cents to dollars (× 0.01), 12 months to 1 year
	/* {
		withSurplus: Math.round(outputs.annual.estimated * inputs.kwhPrice) * .01,
		withoutBuyBack: Math.round((outputs.annual.estimated - outputs.annual.surplus) * inputs.kwhPrice) * .01
	} */
	return outputs;
};
export default calcs