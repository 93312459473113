import "./background-img.css"
import { basename } from './App'

const images = {
    settings: {
        prefix: `images/`,

    },
    sources: [
        {
            src: "pexels-356036",
            ext: 'jpg',
            size: {
                w: 3840,
                h: 3840
            },
            srcset: [
                { suffix: 720, w: 720, h: 720, },
                { suffix: 1280, w: 1280, h: 1280, },
                { suffix: 1440, w: 1440, h: 1440, },
                { suffix: 1920, w: 1920, h: 1920, },
                { suffix: 2880, w: 2880, h: 2880, }
            ]
        }, {
            src: "pexels-9875420",
            ext: 'jpg',
            size: {
                w: 3840,
                h: 3840
            },
            srcset: [
                { suffix: 720, w: 720, h: 720, },
                { suffix: 1280, w: 1280, h: 1280, },
                { suffix: 1440, w: 1440, h: 1440, },
                { suffix: 1920, w: 1920, h: 1920, },
                { suffix: 2880, w: 2880, h: 2880, }
            ]
        }, {
            src: "pexels-9893729",
            ext: 'jpg',
            size: {
                w: 2560,
                h: 2560
            },
            srcset: [
                { suffix: 720, w: 720, h: 720, },
                { suffix: 1280, w: 1280, h: 1280, },
                { suffix: 1440, w: 1440, h: 1440, },
                { suffix: 1920, w: 1920, h: 1920, },
                { suffix: 2880, w: 2880, h: 2880, }
            ]
        },
    ]
}


const BackgroundImage = ({ srcID, style }) => {
    const source=images.sources.hasOwnProperty(srcID) && images.sources[srcID]
    return <div style={!!style?style:{}} className="background-img">{images.sources.hasOwnProperty(srcID) &&
        <img alt="" src={`${basename}${images.settings.prefix}${source.src}.${source.ext}`} srcSet={
            source.srcset.map((src)=>
                `${basename}${images.settings.prefix}${source.src}-w${src.suffix}.${source.ext} ${src.suffix}w`
            ).join(', ')
        } />
    }</div>
}

export default BackgroundImage
/*


public\images\pexels-356036-w720.jpg
public\images\pexels-356036-w1280.jpg
public\images\pexels-356036-w1440.jpg
public\images\pexels-356036-w1920.jpg
public\images\pexels-356036-w2880.jpg
public\images\pexels-356036.jpg
public\images\pexels-9875420-w720.jpg
public\images\pexels-9875420-w1280.jpg
public\images\pexels-9875420-w1440.jpg
public\images\pexels-9875420-w1920.jpg
public\images\pexels-9875420-w2880.jpg
public\images\pexels-9875420.jpg
public\images\pexels-9893729-w720.jpg
public\images\pexels-9893729-w1280.jpg
public\images\pexels-9893729-w1440.jpg
public\images\pexels-9893729-w1920.jpg
public\images\pexels-9893729-w2880.jpg
public\images\pexels-9893729.jpg

https://fresheyesinc.com/wp-content/uploads/2020/07/home_header_bg-land--multicrop__uw--960w.webp 960w

*/

