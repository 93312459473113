// import logo from './logo.svg';
import './App.css';
// import { Routes, Route, useLocation, Outlet, Link } from "react-router-dom";
import { AnimatePresence } from 'framer-motion';
// import './Main'
import React, { useState } from "react";
import Cookies from 'universal-cookie';
import Calculator from './Calculator'
import BackgroundImage from "./BackgroundImage"

export const basename = document.querySelector('base')?.getAttribute('href') ?? '/'

const Header = ({toggleLightDark, colorSchemePreference}) => {

	return (
		<header className="header">
			<div className='header-logo'><Logo /></div>
			<span className='header-text'>
				<span>MO</span><span>Solar</span><span>Calculator.com</span>
			</span>
			{/* <Link className='header-logo' to={basename}>
			{/* </Link>

			<nav>
				<Link key="home" to="/">Home</Link>
				<Link key="calculator" to="/calculator">Calculator</Link>
				<Link key="results" to="/results">Results</Link>
				<Link key="print" to="/print">Print</Link>
			</nav>
			<span>by AV3 Energy</span>
				*/}
			<div className='header-btns'>
				<button className='print-btn' onClick={() => window.print()}>Print</button>
				<div className={`light-dark-toggle light-dark-toggle--${colorSchemePreference||'auto'}`} onClick={toggleLightDark}>
					<span id="light-dark-toggle__thumb">&nbsp;</span>
				</div>
			</div>
		</header>
	);
}

/* const Layout = () => {
	return (<>
		<Outlet />
	</>);
} */

const Footer = () => {
	 // <Link to={basename}></Link>
	return <footer>
		<Logo />
		<div className='copyright'>&copy; {new Date().getFullYear()} AV3 Energy</div>
	</footer>
}


const App = () => {
	const cookies = new Cookies(),
		colorSchemePreferenceFromCookie = cookies.get('colorSchemePreference')

	let [colorSchemePreference, setColorSchemePreference] = useState(colorSchemePreferenceFromCookie || 'auto');
	const toggleLightDark = () => {
		
		switch(colorSchemePreference) {
			case 'auto': cookies.set('colorSchemePreference', 'dark', { path: '/' }); return setColorSchemePreference('dark');
			case 'dark': cookies.set('colorSchemePreference', 'light', { path: '/' }); return setColorSchemePreference('light');
			case 'light': cookies.set('colorSchemePreference', 'auto', { path: '/' }); return setColorSchemePreference('auto');
		}
	}
	// const location = useLocation();
	// const topLevelPage = location.pathname.match(/\/([A-z0-9_-]*)/)[0];
	return <div className={`App color-scheme-${colorSchemePreference||'auto'}`}>
		<Header toggleLightDark={toggleLightDark} colorSchemePreference={colorSchemePreference} />
		<div id="page_wrap" className="page_wrap">
			<Calculator />
		</div>
		<Footer />
	</div>
/* 	return (
		<div className="App">
			<Header />
			<div id="page_wrap" className="page_wrap" key={`page-wrap-${topLevelPage}`}>
				<AnimatePresence mode="sync">
					<Routes
						location={location}
						key={`${topLevelPage}`}
					>
						<Route path={basename} element={<Layout />}>
							{/* <Route index element={<Home />} />
							<Route index element={<Calculator />} />
							<Route path="mosolarcalculator" element={<Calculator />} />
							<Route path="calculator" element={<Calculator />} />
							<Route path="results" element={<Results />} />
							<Route path="print" element={<Print />} />
						</Route>
					</Routes>
				</AnimatePresence>
			</div>
			<Footer />
		</div>
	); */
}

/* const Home = () => {
	return <main key="page-home" className="page-home">
		<BackgroundImage srcID={1} />
		<section key="home__section-intro">
			<h1>Home</h1>
			<p>Intro Section</p>
		</section>
		<section key="home__section-cta">
			<h3>Calculate the value of your solar panel system:</h3>
			<br />
			<Link to="/calculator" >Go to the Calculator</Link>
		</section>
	</main>
}
const Results = () => {
	return <main key="page-results" className="page-results">
		<section key="results__section-display">
			<h1>Results</h1>
			page inner content
		</section>
	</main>
} */
const Print = () => {
	return <main key="page-print" className="page-print">
		<section key="print__section-display">
			<h1>Print</h1>
			page inner content
		</section>
	</main>
}
const Logo = ({ className = '' }) => (<div key={'logo-' + (className && className)} className={(className && className) + ' logo'}><svg version="1.1" viewBox="0 0 256 256">
	<g>
		<text x="31.79" y="175.4" fontFamily="Roboto" fontSize="60px" fontWeight="300"><tspan x="31.79" y="175.4" fontFamily="Roboto" fontSize="60px" fontWeight="900">SOLAR</tspan></text>
		<text x="13.01" y="219.5" fontFamily="Roboto" fontSize="50px" fontWeight="900"><tspan x="13.01" y="219.5" fontFamily="Roboto" fontSize="50px" fontWeight="500">Calculator</tspan></text>
		<path d="m188 126v-6h12v-19.5l10.5-4.5v24h12v6zm-60-38.6c0 8.3 6.8 15 15 15 8.3 0 15-6.7 15-15s-6.7-15-15-15c-8.2 0-15 6.7-15 15zm42 0c0 14.9-12.1 27-27 27-14.8 0-27-12.1-27-27 0-14.91 12.2-27 27-27 14.9 0 27 12.09 27 27zm-142 27v-54h12l24 24 24-24h12v54h-12v-37.5l-19.5 19.5h-9l-19.5-19.5v37.5zm96-54-60-30-36 18v-12h-12v18l-12 6v3h12v63h96v-63h12zm108.9 8.59 6.6 10.1 11.8-5.79-6.1-9.19zm-8.4-12.65 6.6 10.11 12.5-4.65-6.1-9.18zm-8.5-12.61 6.7 10.12 13.2-3.53-6.1-9.2zm-8.4-12.65 6.7 10.11 14.1-2.38-6.2-9.18zm4.5 46.2 7.8 11.68 15.4-7.65-7.2-10.43zm-28.3 11.31 9.2 13.81 21-10.47-8.1-12.15zm18.7-25.87 7.6 11.67 16.4-6.15-7.1-10.45zm-30.2 8.66 9.2 13.79 22.5-8.4-8.2-12.14zm20.3-23.24 7.9 11.67 17.4-4.62-7-10.46zm-9.6-14.59 7.7 11.69 18.3-3.14-6.8-10.46zm-22.1 20.6 9.2 13.8 23.6-6.34-7.9-12.14zm-11.6-17.25 9.2 13.79 24.9-4.28-7.9-12.13z" />
	</g>
</svg></div>)

export default App;