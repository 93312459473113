import { type } from "@testing-library/user-event/dist/type";
import { getValue } from "@testing-library/user-event/dist/utils";


export const
	objCopy = (obj) => (
		typeof obj == "object" ? JSON.parse(
			JSON.stringify(obj)
		) : typeof obj
	),
	merge = (t, s) => {
		var target = t, source = s;
		Object.keys(source).forEach(key => {
			if (target.hasOwnProperty(key) && typeof source[key] == "object") target[key] = merge(target[key], source[key]);
			else target[key] = source[key];
		});
		return target;
	},
	recursiveUpdate = (obj, prop, value, level = 0) => {
		if (typeof prop == "undefined" || typeof obj != "object" || !obj.hasOwnProperty(prop[prop.length - 1])) {
			console.log({
				failure: 'update failed',
				obj: obj,
				prop: prop,
				value: value,
				level: level
			});
			return false;
		}
		var prop_ = objCopy(prop), prop__ = prop_.pop();
		if (prop_.length) {
			obj[prop__] = recursiveUpdate(obj[prop__], prop_, value, level + 1);
		} else {
			obj[prop__] = value;
			// console.log(prop_, value, level)
		}
		/* if (!level) console.log({
			// failure: 'update failed',
			obj: obj,
			prop: prop,
			value: value,
			level: level
		}); */
		return obj;
	},
	// getEl = (el) => typeof el == "string" ? document.querySelector(selector) : el,
	getMin = (el) => el.hasOwnProperty('min') ? el.min : (el.min = parseFloat(el.getAttribute('min'))),
	getMax = (el) => el.hasOwnProperty('max') ? el.max : (el.max = parseFloat(el.getAttribute('max'))),
	getStep = (el) => el.hasOwnProperty('step') ? el.step : (el.step = parseFloat(el.getAttribute('step'))),
	getVal = (el) => {
		// el = getEl(el);
		var v = parseFloat(getValue(el)), p = parseFloat(el.getAttribute('placeholder')), r = typeof v == "number" && !isNaN(v) ? v : (typeof p == "number" && !isNaN(p) ? p : 0);;
		// console.log({el, v, p, r})
		return r;
	},
	setVal = (el, value) => {
		// el = getEl(el);
		el.value = value;
		el.dispatchEvent(new Event('change'))
		el.dispatchEvent(new Event('change'))
		return value
		var v = getValue(el), p = el.getAttribute('placeholder');
		// console.log(el, v, p)
		return parseFloat(typeof v == "number" ? v : typeof p == "number" ? v : 0);
	},
	clampVal = (el, val, min, max) => {
		if (isNaN(val)) val = getVal(el)
		if (!isNaN(min)) val = Math.max(min, val)
		if (!isNaN(max)) val = Math.min(max, val)
		el.value = val;
		el.dispatchEvent(new Event('change'));
		return el.value
	},
	stepVal = (el, step, min, max) => /* console.log({ el, step, min, max, val:getVal(el) }) && */ clampVal(el, parseFloat(getVal(el)) + step, min, max),
	increment = (selector) => { const el = document.querySelector(selector), att = (prop) => parseFloat(el.getAttribute(prop)); return !!el ? stepVal(el, +att('step'), att('min'), att('max')) : console.log("fail", selector, el) },
	decrement = (selector) => { const el = document.querySelector(selector), att = (prop) => parseFloat(el.getAttribute(prop)); return !!el ? stepVal(el, -att('step'), att('min'), att('max')) : console.log("fail", selector, el) },
	buildFunc = (
		selector, args, func = (a) => a, callback = (a) => a
	) => (
		() => { var el = document.querySelector(selector); if (el) callback(func(el), args) }
	)(selector, args),
	buildInc = (selector, callback = (a) => a, prop) => () => !!callback ? callback(increment(document.querySelector(selector)), prop) : increment(document.querySelector(selector)),
	buildDec = (selector, callback = (a) => a, prop) => () => !!callback ? callback(decrement(document.querySelector(selector)), prop) : decrement(document.querySelector(selector));
