import React, { useEffect, useState } from "react";
import Cookies from 'universal-cookie';
import './calc.css'
import BackgroundImage from "./BackgroundImage"
import calcs from "./calcs";
import { increment, decrement, objCopy, recursiveUpdate } from "./inputHandlers";
// import { CalcInputs } from "./CalcInputs";

export const directions = [
	'South', 'West', 'East', 'North'
], months = [
	'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
	// 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
], fields = {
	kwhPrice: ({ label: "Price", min: 0, max: 999, step: 0.1, default: 9, name: 'kwhPrice', className: ['price-per-kwh'], unitText: <><sup>¢</sup>/<sub>kWh</sub></> }), // in cents
	usage: (() => {
		var a = [];
		const b=/* (i)=>Math.round(Math.sin((i - 2.5) * Math.PI / 6) * 50 + 150)*5 // */ [ 1400, 1500, 1100, 850, 700, 900, 1200, 1200, 800, 700, 700, 1250]; // 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
		months.map((month, i) => a.push({
			label: month, min: 0, max: 99995, step: 5, default: b[i], name: `usage-${month.toLowerCase()}`, className: ['usage-month'], unitText: 'kWh'
		}));
		return a
	})(),
	panels: {
		power: { label: "Panel Wattage", unitText: "W", min: 0, max: 9999, step: 1, default: 400, className: ['panel-prop', 'panel-power'], name: `panel-power` },
		angle: { label: "Tilt Angle", unitText: "°", min: 0, max: 40, step: 1, default: 25, className: ['panel-prop', 'panel-angle'], name: `panel-angle` },
		shade: { label: "Shading Loss", unitText: "%", min: 0, max: 100, step: 1, default: 5, className: ['panel-prop', 'panel-shade'], name: `panel-shade` },
		qty: (() => {
			var a = {}; directions.forEach(d => a[d.toLowerCase()] =
				{ label: d, min: 0, max: 999, step: 1, default: 0, className: ['panel-prop', 'panel-qty'], name: `panel-qty-${d.toLowerCase()}` }
			); return a
		})()
	}
}, sanitizeInputs = (newInputs, updateValues = (inputs) => {console.log(inputs)}) => {
	const newInputsOriginal = objCopy(newInputs)
	months.forEach((Month, i) => {
		var month = Month.toLowerCase()
		newInputs.usage[i] = Math.min(fields.usage[i].max, Math.max(fields.usage[i].min, 1 * newInputs.usage[i]))
	})
	newInputs.kwhPrice = Math.min(fields.kwhPrice.max, Math.max(fields.kwhPrice.min, 1 * newInputs.kwhPrice))
	directions.forEach(Dir => {
		var dir = Dir.toLowerCase();
		newInputs.panels.qty[dir] = Math.min(fields.panels.qty[dir].max, Math.max(fields.panels.qty[dir].min, 1 * newInputs.panels.qty[dir]))
		
	})
	newInputs.panels.power = Math.min(fields.panels.power.max, Math.max(fields.panels.power.min, 1 * newInputs.panels.power))
	newInputs.panels.angle = Math.min(fields.panels.angle.max, Math.max(fields.panels.angle.min, 1 * newInputs.panels.angle))
	newInputs.panels.shade = Math.min(fields.panels.shade.max, Math.max(fields.panels.shade.min, 1 * newInputs.panels.shade))
	// if (JSON.stringify(newInputsOriginal) != JSON.stringify(newInputs)) {
	// 	// console.log('inputs sanitized', newInputsOriginal, newInputs)
	// 	!!updateValues&&updateValues()
	// }
	return newInputs
}, fitScale = (max) => {
	var cap = 100, step = 10, scale = [], i = 0;
	while (cap < max) { step = cap; cap *= 10 }
	if (cap > max * 2) if (cap > max * 4) {
		step >>= 2
		cap >>= 2
	} else {
		step >>= 1
		cap >>= 1
	}
	// if(typeof scale!="object"||scale.length>20)scale=[]
	while ((i) * step <= max && i < 20) { scale.push(i * step); i++ }
	return scale
}, getDefaults = ((fields) => {
	if (typeof fields == "object" && fields.hasOwnProperty('default')) return fields.default
	Object.keys(fields).forEach(field => fields[field] = getDefaults(fields[field]))
	return fields
}), defaultValues = {
	fields: getDefaults(objCopy(fields)),
	outputs: {
		perMonth: (() => {
			var a = []; months.map((month, i) => a.push({
				kWh: 0,
				lessUsage: 0,
			})); return a
		})(),
		perYear: {
			kWh: 0,
			lessUsage: 0,
		}
	},
}
const CalcOutputs = ({ inputs }) => {

	return <>
	</>
}
const Calculator = () => {
	const cookies = new Cookies(),
		inputsFromCookie = cookies.get('CalculatorUserInputs'),
		showDebugFromCookie = cookies.get('CalculatorShowDebug')

	let [inputs, setInputs] = useState((inputsFromCookie) || defaultValues.fields);

	let [showDebug, setShowDebug] = useState(showDebugFromCookie);

	const toggleShowDebug = () => {
		cookies.set('CalculatorShowDebug', !showDebug, { path: '/' });
		setShowDebug(!showDebug);
	},toggleShowDebugOnClick = (e) => {	e.preventDefault(); toggleShowDebug()}

	const setValuesToCookies = () => {
		// console.log('setting values from cookies');
		document.querySelectorAll('input').forEach(input => {
			if (input.getAttribute('cookie-value')) input.setAttribute('value', input.getAttribute('cookie-value'));
		});
	}, onInput = (value, prop) => {
		// var value=(typeof e=="object"&&e.hasOwnProperty('target')&&typeof e.target=="object"&&e.target.hasOwnProperty('value'))?e.target.value:e,
		const newInputs =//objCopy(inputs)
			sanitizeInputs(recursiveUpdate(objCopy(inputs), objCopy(prop), parseFloat(value)))
		// setInputs(inputs => inputs = sanitizeInputs(newInputs))
		cookies.set('CalculatorUserInputs', newInputs, { path: '/', expires: new Date(Date.now()+31556736) });
		console.log(value)
		console.log(prop)
		console.log(newInputs)
		// inputs=
		// 
		setOutputs(calcs(newInputs))
		setInputs(inputs => inputs = newInputs)
	}, pasteUsageValues = async (e) => {
		e.preventDefault()
		const text = await navigator.clipboard.readText()
		// alert(text)
		if (!text) return ;//alert('No text found in clipboard')

		var numbers = text.match(/(\d+)/g)
		if (!numbers) return alert('no numbers found in clipboard')
		if (numbers.length < 12) return alert('Not enough values found in clipboard')
		var newInputs=objCopy(inputs)
		for(var i in months)newInputs.usage[i] = parseInt(numbers[i])
		newInputs=sanitizeInputs(newInputs)
		setOutputs(calcs(newInputs))
		setInputs(inputs => inputs = newInputs)
		/* document.querySelectorAll('[name="month-usage"]').forEach((input, i) => {
			input.setAttribute('cookie-value', newInputs.usage[i]);
			input.setAttribute('value', newInputs.usage[i]);
		}); */
		cookies.set('CalculatorUserInputs', newInputs, { path: '/', expires: new Date(Date.now()+31556736) });
		alert(numbers.join(', '))
		return false; // Prevent the default handler from running.
		/* 380, 635, 860, 1045, 1175, 1240, 1240, 1175, 1045, 860, 635, 380 */
	}
	let [outputs, setOutputs] = useState(calcs(!!inputs ? inputs : defaultValues.fields)); // calcs(inputs)
	useEffect(() => setValuesToCookies(), []);


	// function incrementThis() { return `const att=(p)=>this.getAttribute;var el=document.querySelector(att('input'));el.value=Math.min(att('max'), Math.max(att('min'), el.value)) + att('step');el.dispatchEvent(new Event('change'))` }
	// function decrementThis() { return `const att=(p)=>this.getAttribute;var el=document.querySelector(att('input'));el.value=Math.min(att('max'), Math.max(att('min'), el.value)) - att('step');el.dispatchEvent(new Event('change'))` }


	// const onChangeHandler = (newInputs) => {
	// 	// setInputs(inputs=>inputs=newInputs)
	// 	setOutputs(!!inputs ? calcs(newInputs) : {})
	// }
	// !!inputsFromCookie &&
	// inputsFromCookie.hasOwnProperty('usage') &&
	// inputsFromCookie.usage.hasOwnProperty(i) ?
	// inputsFromCookie.usage[i] :
	// null
	const inputsNoUsage = !inputsFromCookie?.usage?.reduce((a, b) => a + b, 0)
	
	return <main key="page-calc" className="page-calc">
		<BackgroundImage style={{ opacity: .2 }} srcID={0} />
		<section className="inputs_wrap" key="inputs_wrap">
			<h3 key="inputs__heading" className="inputs__heading">Inputs</h3>
			<form className="inputs_wrap">
				<div className="inputs_wrap__inner inputs_wrap__inner--panels_and_price">
					<div className="inputs__panel_props" key="inputs__panel_props">
						<h4 style={{ width: '100%' }}>System Design</h4>
						<div className="fields__wrap">
							{['power', 'angle', 'shade'].map(p => !!p &&
								(
									(propName, prop) => <label
										key={propName}
										className={`input__field input__field--${typeof prop.label == "object" ? prop.label : 'label'}`}
									>
										<span className="input__field__label">{prop.label}</span>
										<div id={`field-${prop.name}`} className="input__field__inner">
											<input
												name={prop.name}
												size={5}
												placeholder={prop.default}
												id={`input-${prop.name}`}
												autoComplete={`input-${prop.name}`}
												type="number"
												min={prop.min}
												step={prop.step}
												max={prop.max}
												onChange={(e) => onInput(e.target.value, [p, 'panels'])}
												className={"input__field__input"}
												cookie-value={!!inputsFromCookie &&
													inputsFromCookie.hasOwnProperty('panels') &&
													inputsFromCookie.panels.hasOwnProperty(p) ?
													inputsFromCookie.panels[p] :
													null} />
											<span className="input__field__unit_after">{!!prop.unitText && prop.unitText}</span>
										</div>
										<div className="input__field__inc-dec">
											<span className="input__field__increment" onClick={e => { e.preventDefault(); onInput(increment((a => a)(`#input-${prop.name}`)), ((a => a)([p, 'panels']))) }}>+</span>
											<span className="input__field__decrement" onClick={e => { e.preventDefault(); onInput(decrement((a => a)(`#input-${prop.name}`)), ((a => a)([p, 'panels']))) }}>-</span>
										</div>
										{/* ((el, prop)=>()=>onInput(increment(document.querySelector(el)),prop))(`#input-${prop.name}`, [p, 'panels']) }> */}
										{/* ((el, prop)=>()=>onInput(decrement(document.querySelector(el)),prop))(`#input-${prop.name}`, [p, 'panels']) }> */}
									</label>
								)(p, fields.panels.hasOwnProperty(p) ? fields.panels[p] : "doesn't exist")
							)}
						</div>
					</div>
					<div className="inputs__panel_qty" key="inputs__panel_qty">
						<h4>Number of Panels</h4>
						<div className="fields__wrap">
							{Object.keys(fields.panels.qty).map(dir => <label
								className={`input__field input__field--${dir}`}
								key={`qty-${dir}`}
							>
								<span className="input__field__label input__field__label--facing">Facing {fields.panels.qty[dir].label}</span>
								<div id={`field-${fields.panels.qty[dir].name}`} className="input__field__inner">
									<input
										name={fields.panels.qty[dir].name}
										size={5}
										placeholder={fields.panels.qty[dir].default}
										id={`input-${fields.panels.qty[dir].name}`}
										autoComplete={`input-${fields.panels.qty[dir].name}`}
										type="number"
										min={fields.panels.qty[dir].min}
										step={fields.panels.qty[dir].step}
										max={fields.panels.qty[dir].max}
										onChange={(e) => onInput(e.target.value, [dir, 'qty', 'panels'])}
										cookie-value={!!inputsFromCookie &&
											inputsFromCookie.hasOwnProperty('panels') &&
											inputsFromCookie.panels.hasOwnProperty('qty') &&
											inputsFromCookie.panels.qty.hasOwnProperty(dir) ?
											inputsFromCookie.panels.qty[dir] :
											null}
										className={"input__field__input"} />
									<span className="input__field__unit_after">{!!fields.panels.qty[dir].unitText && fields.panels.qty[dir].unitText}</span>
								</div>
								<div className="input__field__inc-dec">
									<span className="input__field__increment" onClick={e => { e.preventDefault(); onInput(increment((a => a)(`#input-${fields.panels.qty[dir].name}`)), ((a => a)([dir, 'qty', 'panels']))) }}>+</span>
									<span className="input__field__decrement" onClick={e => { e.preventDefault(); onInput(decrement((a => a)(`#input-${fields.panels.qty[dir].name}`)), ((a => a)([dir, 'qty', 'panels']))) }}>-</span>
								</div>
							</label>)}
						</div>
					</div>
					<div className="inputs__price" key="inputs__price">
						<h4>Price of Electricity</h4>
						<div className="fields__wrap">
							<label
								className={`input__field input__field--${typeof fields.kwhPrice.label == "object" ? fields.kwhPrice.label : 'label'}`}
							>
								<span className="input__field__label">{fields.kwhPrice.label}</span>
								<div id={`field-${fields.kwhPrice.name}`} className="input__field__inner">
									<input
										name={fields.kwhPrice.name}
										size={5}
										placeholder={fields.kwhPrice.default}
										id={`input-price`}
										autoComplete={`input-price`}
										type="number"
										min={fields.kwhPrice.min}
										step={fields.kwhPrice.step}
										max={fields.kwhPrice.max}
										onChange={(e) => onInput(e.target.value, ['kwhPrice'])}
										cookie-value={!!inputsFromCookie &&
											inputsFromCookie.hasOwnProperty('kwhPrice') &&
											inputsFromCookie ?
											inputsFromCookie.kwhPrice :
											null}
										className={"input__field__input"} />
									<span className="input__field__unit_after">{!!fields.kwhPrice.unitText && fields.kwhPrice.unitText}</span>
								</div>
								<div className="input__field__inc-dec">
									<span className="input__field__increment" onClick={e => { e.preventDefault(); onInput(increment((a => a)(`#input-price`)), ((a => a)(['kwhPrice']))) }}>+</span>
									<span className="input__field__decrement" onClick={e => { e.preventDefault(); onInput(decrement((a => a)(`#input-price`)), ((a => a)(['kwhPrice']))) }}>-</span>
								</div>
							</label>
						</div>
					</div>
				</div>
				<div className="inputs_wrap__inner inputs_wrap__inner--usage">
					<div className="inputs__usage" key="inputs__usage">
						<h4>Average Monthly Usage</h4>
						<div className="fields__wrap">
							{months.map((Month, i) => <label
								key={`usage-input-${Month.toLowerCase()}`}
								id={`usage-input-${Month.toLowerCase()}`}
								htmlFor={`input-${Month.toLowerCase()}`}
								className={`input__field input__field--${typeof fields.usage[i].label == "object" ? fields.usage[i].label : 'label'}`}
							>
								<span className="input__field__label">{fields.usage[i].label}</span>
								<div id={`field-${fields.usage[i].name}`} className="input__field__inner">
									<input
										name={"month-usage[]"/* fields.usage[i].name */}
										size={5}
										placeholder={fields.usage[i].default}
										id={`input-${fields.usage[i].name}`}
										autoComplete={`input-${fields.usage[i].name}`}
										type="number"
										min={fields.usage[i].min}
										step={fields.usage[i].step}
										max={fields.usage[i].max}
										onChange={(e) => onInput(e.target.value, [i, 'usage'])}
										cookie-value={!!inputsFromCookie &&
											inputsFromCookie.hasOwnProperty('usage') &&
											inputsFromCookie.usage.hasOwnProperty(i) ?
											inputsFromCookie.usage[i] :
											null}
										className={"input__field__input"} />
									<span className="input__field__unit_after">{!!fields.usage[i].unitText && fields.usage[i].unitText}</span>
								</div>
								<div className="input__field__inc-dec">
									<span className="input__field__increment" onClick={e => { e.preventDefault(); onInput(increment((a => a)(`#input-${fields.usage[i].name}`)), ((a => a)([i, 'usage']))) }}>+</span>
									<span className="input__field__decrement" onClick={e => { e.preventDefault(); onInput(decrement((a => a)(`#input-${fields.usage[i].name}`)), ((a => a)([i, 'usage']))) }}>-</span>
								</div>
							</label>)}
						</div>
						{inputsNoUsage && <p style={{ width: "20rem" }}><em>The usage show is for an average 2000 sq ft house with electric heat.  For a more accurate analysis please enter the data for your own past electric bills</em></p>}
						<div>
							<button
								id="inputs__usage__paste"
								key="inputs__usage__paste"
								className="input__field input__field--btn inputs__usage__paste"
								onClick={pasteUsageValues}
								tooltip="Copy your usage values from a spread sheet or PDF and paste into the fields"
							>
								<span className="input__field__label">Paste Values</span>
							</button>
						</div>
					</div>
				</div>
			</form>
		</section>
		<section key="calculation_report" className="calculation_report">
			<div className="calculation_report__outputs">
				<div className="outputs">
					<h4>Kwh's Generated Per Year</h4>
					<div className="table_wrap">
						{directions.map((Dir, i) => {
							var dir = Dir.toLowerCase(); return <div className="table_row" key={`${dir}-output`}>
								<span className="table_cell">{Dir} Panel{inputs.panels.qty[dir] == 1 ? '' : 's'}:</span>
								<span className="table_cell">{Math.round(outputs.perDirection[dir].annualEstimated / 10) * 10}<span className="report__unit">kWh/yr</span></span>
							</div>
						})}
						<div className="table_row table_row--total" key={`total-output`}>
							<span className="table_cell">Total:</span>
							<span className="table_cell">{Math.round(outputs.annual.estimated / 10) * 10}<span className="report__unit">kWh/yr</span></span>
						</div>
					</div>
				</div>
				<div className="outputs outputs--result">
					<h3 className="avg_red_result">Average Reduction of<br />Your Electric&nbsp;Bill***</h3>
					<span className="highlight"><span className="report__unit report__unit--pre">$</span>{outputs.billReduction}<span className="report__unit">per&nbsp;mo</span></span>
				</div>
			</div>

			<div className="calculation_report__notes">
				<h6>Notes</h6>
				<div className="note">
					<p>Electric rates will go up over time which will increase the savings. A good rule of thumb is to utilize the extra savings to cover the cost of replacing the inverter(s) at least once over the life of&nbsp;the&nbsp;system.</p>
				</div>
				<div className="note">
					<p>To calculate payback take your final cost after tax credit, rebates, and interest and divide&nbsp;by&nbsp;${outputs.billReduction * 12}.</p>
				</div>
			</div>
		</section>
		<section key="outputs_graph" className="outputs_graph">
			<h3 key="outputs__heading" className="outputs__heading">Estimated Monthly Electric Usage & Generation&nbsp;(Kwh)</h3>
			<svg viewBox="-2 10 132 70" style={{ overflow: 'visible' }}>
				<g key="outputs_graph__grid" className="outputs_graph__grid">
					<path className="graph-grid" d={fitScale(outputs.max).map(step =>
						`M 7.5,${(60 - 50 * outputs.normalize * step).toFixed(4)} 130,${(60 - 50 * outputs.normalize * step).toFixed(4)}`
					).join(' ')} />
					{fitScale(outputs.max).map((step, i) => (
						<text key={`graph-grid__label--${i}`} className="graph-grid__label" textAnchor={'end'} x={6} y={(60 - 50 * outputs.normalize * step).toFixed(4)} fontSize={3.5}><tspan>{step}</tspan></text>
					))}
				</g>
				<g key="outputs_graph__data" className="outputs_graph__data">
					{outputs.source.usage.map((month, i) => (
						<rect key={`month-${i}-rect`} className={`bar-graph__bar usage-month usage-month--${outputs.monthlyExcess[i] ? 'excess' : 'under'}`} x={i * 10 + 12.25} y={(60 - 50 * outputs.normalize * month).toFixed(4)} width={3} height={(50 * outputs.normalize * month).toFixed(4)} />
					))}
					{outputs.source.usage.map((month, i) => (<text
						key={`month-${i}-text-name`}
						className={`bar-graph__label usage-month--month usage-month--${outputs.monthlyExcess[i] ? 'excess' : 'under'}`}
						x={-60} y={i * 10 + 15} transform="rotate(-90)" fontSize={3.5}
					>
						<tspan textAnchor={'end'}>{months[i]}&nbsp;</tspan>
					</text>))}
					{outputs.source.usage.map((month, i) => (<text
						key={`month-${i}-text-value`}
						className={`bar-graph__label usage-month--usage usage-month--${outputs.monthlyExcess[i] ? 'excess' : 'under'}`}
						x={-59} y={i * 10 + 15} transform="rotate(-90)" fontSize={3.5}
					>
						<tspan> {month}</tspan>
					</text>))}
				</g>
				<path className="line-graph generated-month" d={"M " + outputs.monthlyGenerated.map((month, i) =>
					`${i * 10 + 14},${(60 - 50 * outputs.normalize * month).toFixed(4)}`
				).join(' ')} />
			</svg>
			{/* <button className="outputs__toggle_debug" onClick={toggleShowDebugOnClick}>Toggle Debug</button>
			{showDebug&&<pre style={{whiteSpace: "pre-wrap", textAlign: "left", backgroundColor: "rgb(0 0 0/.7)", padding: "1em"}}>
				{JSON.stringify(outputs, "\n", "\t")}
			</pre>} */}
		</section>
		<section className="info-instruct" key="info-instruct">
			<div className="page-width-content" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
				<h6>Instructions & Information</h6>
				<p style={{ textAlign: 'left', maxWidth: "max(50em)" }}>
					* Tilt = 0° for horizontal surfaces; 15° = 3/12 pitch, 20° = 4/12 pitch, 25° = 5/12 pitch, 30° = 6/12 pitch or actual angle for ground-mounted systems,&nbsp;typically&nbsp;30°.
				</p>
				<p style={{ textAlign: 'left', maxWidth: "max(50em)" }}>
					** Shading losses = 0% for no trees or structures blocking sun from panels; can be as high as 50% depending on thickness and height&nbsp;of&nbsp;trees/structures
				</p>
				<p style={{ textAlign: 'left', maxWidth: "max(50em)" }}>
					*** This estimate should be accurate to within 10%. It will be more accurate if you fill out the past&nbsp;usage&nbsp;chart. Additionally, the rate for overproduction buy-back is calculated at 2¢/kWh.
				</p>
			</div>
		</section>
	</main>
}

export default Calculator